import React from 'react';

const Footer = () => {
    return (
        <footer>
            <p> Webpage created by Samuel Smith N11064196</p>
            <p> This website is for the purposes of CAB432 assessment 1</p>
        </footer>
    );
};

export default Footer;