import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import VideoPage from './pages/VideoPage';
import EnableTOTP from './pages/EnableTOTP';
import './css/overallStyle.css';
import Footer from './components/Footer';
import './App.css';
import { AuthenticationProvider } from './components/AuthenticationContext';

function App() {
    return (
        <AuthenticationProvider>
            <Router>
                <div id="app-wrapper" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                    <main style={{ flex: '1' }}>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/register" element={<Register />} />
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/videos/:id" element={<VideoPage />} />
                            <Route path="/enable-totp" element={<EnableTOTP />} />
                        </Routes>
                    </main>
                    <Footer />
                </div>
            </Router>
        </AuthenticationProvider>
    );
}

export default App;
