import React, { createContext, useState, useEffect } from 'react';

export const AuthenticationContext = createContext();

export const AuthenticationProvider = ({ children }) => {
    const [auth, setAuth] = useState(() => {
        // On initial load, check if tokens are in cookies
        const idToken = getCookie('idToken');
        const accessToken = getCookie('accessToken');
        const refreshToken = getCookie('refreshToken');
        return idToken && accessToken ? { idToken, accessToken, refreshToken } : null;
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkAuth = async () => {
            // Retrieve the token from cookies
            const token = getCookie('idToken'); 
            const accessToken = getCookie('accessToken');
            const refreshToken = getCookie('refreshToken');

            if (!token) {
                setAuth(null);  // No token, not authenticated
                setLoading(false); // Finish loading
                return;
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/check-auth`, {
                    method: 'GET',
                    credentials: 'include',
                });

                if (response.ok) {
                    const data = await response.json();
                    setAuth({ ...data, token, accessToken, refreshToken }); // Set the auth state with user data and token
                } else {
                    setAuth(null); // Token verification failed, set auth to null
                }
            } catch (error) {
                setAuth(null); // In case of error, set auth to null
            }
            setLoading(false); // Finish loading once the check is done
        };

        checkAuth(); // Run the check on page load or reload
    }, []); 

    const login = async (email, password) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/login`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, password }),
                credentials: 'include',  // Ensure cookies are sent and received
            });

            const data = await response.json();

            if (response.ok) {
                if(data.challengeName){
                    return data;
                }else{
                // Tokens are already set in cookies by the backend
                const idToken = getCookie('idToken');
                const accessToken = getCookie('accessToken');
                const refreshToken = getCookie('refreshToken');

                setAuth({ idToken, accessToken, refreshToken });
                return { success: true };
                }
                
            } else {
                throw new Error(data.error);
            }
        } catch (error) {
            throw new Error(error.message);
        }
    };

    const logout = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/login/logout`, {
                method: 'POST',
            });

            if (response.ok) {
                // Clear tokens from cookies
                document.cookie = 'idToken=; Max-Age=0';
                document.cookie = 'accessToken=; Max-Age=0';
                document.cookie = 'refreshToken=; Max-Age=0';
                setAuth(null);
                setLoading(false);
                setTimeout(() => { window.location.reload(); }, 1000);
            } else {
                throw new Error('Logout failed');
            }
        } catch (error) {
            console.error('An error occurred during logout:', error);
        }
    };

    const verifyTotpLogin = async (totpCode, session, username) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/verify-totp-login`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ totpCode, session, username }),
                credentials: 'include',
            });
    
            const data = await response.json();
    
            if (response.ok) {
                // Tokens are set in cookies by the backend
                const idToken = getCookie('idToken');
                const accessToken = getCookie('accessToken');
                const refreshToken = getCookie('refreshToken');
    
                setAuth({ idToken, accessToken, refreshToken });
                return { success: true };
            } else {
                return { success: false, error: data.error };
            }
        } catch (error) {
            return { success: false, error: error.message };
        }
    };

    return (
        <AuthenticationContext.Provider value={{ auth, loading, login, logout, verifyTotpLogin }}>
            {children}
        </AuthenticationContext.Provider>
    );
};




// Helper function to retrieve the token from cookies - https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
}
