import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated import
import App from './App';
import { AuthenticationProvider } from './components/AuthenticationContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthenticationProvider>
    <App />
  </AuthenticationProvider>
);
