import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { AuthenticationContext } from '../components/AuthenticationContext';

// Dashboard component
function Dashboard() {
    const [videos, setVideos] = useState([]);
    const { auth, logout, loading } = useContext(AuthenticationContext);
    const [error, setError] = useState(null);
    const [progress, setProgress] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        console.log('Auth state:', auth);
        console.log('Loading state:', loading);
        if (!auth && !loading) {
            navigate('/');
            return;
        } else {
            const fetchVideos = async () => {
                console.log('Fetching videos...');
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/videos/fetch`, {
                        method: 'GET',
                        credentials: 'include',
                    });
    
                    if (response.ok) {
                        const data = await response.json();
                        
                        // For each video, get its pre-signed URL for playback
                        const videosWithUrls = await Promise.all(
                            data.map(async (video) => {
                                const videoUrlResponse = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/videos/${video.id}/play`, {
                                    credentials: 'include',
                                   
                                });
    
                                if (videoUrlResponse.ok) {
                                    const videoUrlData = await videoUrlResponse.json();
                                    return { ...video, url: videoUrlData.url }; // Attach URL to video object
                                } else {
                                    console.error('Failed to fetch video URL');
                                    return { ...video, url: null }; // Handle error, no URL
                                }
                            })
                        );
    
                        setVideos(videosWithUrls); // Set videos with their S3 URLs
                    } else {
                        setError('Failed to fetch videos');
                    }
                } catch (err) {
                    setError('An error occurred while fetching videos');
                }
            };
    
            fetchVideos();
        }
    }, [auth, navigate]);

    // Handle video upload - https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
    const handleUpload = async (event) => {
        console.log('Uploading video...');
        event.preventDefault();
        const file = event.target.files[0];
        if (!file) {
            return;
        }
        const formData = new FormData();
        formData.append('file', file);
        formData.append('video_name', file.name);
        console.log('auth token:', auth.token);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/videos/upload`, {
                method: 'POST',
                credentials: 'include',
                body: formData,
            });
            if (response.ok) {
                const newVideo = await response.json();
                setVideos([...videos, newVideo]);
            } else {
                setError('Failed to upload video');
            }
        } catch (err) {
            setError('An error occurred while uploading video');
        }
    };

    

    // Handle video deletion 
    const handleDelete = async (video_id) => {
        console.log('Deleting video:', video_id);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/videos/delete`, {
                method: 'DELETE',
                credentials: 'include', // Ensure cookies are sent
                headers: {
                    'Content-Type': 'application/json', // Specify the JSON content type
                },
                body: JSON.stringify({ video_id }), // Serialize the video_id as JSON
            });
    
            if (response.ok) {
                const updatedVideos = videos.filter(video => video.id !== video_id);
                setVideos(updatedVideos);
            } else {
                const errorData = await response.json();
                setError(errorData.error || 'Failed to delete video');
            }
        } catch (err) {
            setError('An error occurred while deleting the video');
            console.error('Error deleting video:', err);
        }
    };

    if (!auth) {
        return null;
    }
    // Return the dashboard
    return (
        <div>
            <h1>Dashboard</h1>
            <button onClick={logout}>Logout</button>

            <h2>Your Videos</h2>
            {error && <p style={{ color: 'red' }}>{error}</p>}

            {videos.length === 0 && !error && (
                <p>No videos found. Upload a video to get started!</p>
            )}

            {/* Video Upload Section */}
            <h3>Upload Video</h3>
            <input type="file" onChange={handleUpload} />

            {/* Video List with Actions */}
            <h3>Manage Your Videos</h3>
            <ul>
                {videos.map(video => (
                    <li key={video.id}>
                        <div>
                        <Link to={`/videos/${video.id}`}>Manage Video {video.video_name}</Link>
                        <button onClick={() => handleDelete(video.id)}>Delete</button>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}


export default Dashboard;
