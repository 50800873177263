import React, { useState, useContext, useEffect } from 'react';
import { AuthenticationContext } from '../components/AuthenticationContext';
import { useNavigate } from 'react-router-dom';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { login, verifyTotpLogin } = useContext(AuthenticationContext);
    const navigate = useNavigate();
    const [totpCode, setTotpCode] = useState('');
    const [challengeName, setChallengeName] = useState('');
    const [session, setSession] = useState('');
    const [domain, setDomain] = useState('');
    const [clientId, setClientId] = useState('');
    const [redirectUri, setRedirectUri] = useState('');

    useEffect(() => {
        const url = process.env.REACT_APP_COGNITO_DOMAIN;
        const id = process.env.REACT_APP_COGNITO_APP_CLIENT_ID;
        const uri = process.env.REACT_APP_REDIRECT_URI;
        setDomain(url);
        setClientId(id);
        setRedirectUri(uri);
    }, []);

    // Handle login form submission - source: https://reactrouter.com/web/example/auth-workflow
    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await login(email, password);
    
            if (response.challengeName === 'MFA_SETUP') {
                // Navigate to Enable TOTP page with the session and username
                navigate('/enable-totp', { state: { session: response.session, username: email } });
            } else if (response.challengeName === 'SOFTWARE_TOKEN_MFA') {
                // Prompt for TOTP code
                setChallengeName(response.challengeName);
                setSession(response.session);
            } else if (response.success) {
                navigate('/dashboard');
            }
        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    const handleSubmitTotp = async (e) => {
        e.preventDefault();
        try {
            const response = await verifyTotpLogin(totpCode, session, email);
            if (response.success) {
                navigate('/dashboard');
            } else {
                throw new Error(response.error);
            }
        } catch (error) {
            console.error('Failed to verify TOTP:', error);
        }
    };

    const handleGoogleLogin = () => {        
        const googleAuthUrl = `https://${domain}/oauth2/authorize?identity_provider=Google&redirect_uri=${redirectUri}&response_type=code&client_id=${clientId}&scope=email+openid+profile`;

        window.location.href = googleAuthUrl;
       };

    return (
        <div>
            <h1>Login</h1>
            {challengeName === 'SOFTWARE_TOKEN_MFA' ? (
                <form onSubmit={handleSubmitTotp}>
                    <input
                        type="text"
                        placeholder="Enter TOTP Code"
                        value={totpCode}
                        onChange={(e) => setTotpCode(e.target.value)}
                    />
                    <button type="submit">Verify TOTP</button>
                </form>
            ) : (
                <form onSubmit={handleLogin}>
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button type="submit">Login</button>
                </form>
            )}
            <button onClick={handleGoogleLogin}>Login with Google</button>
        </div>
    );
    
}

export default Login;
