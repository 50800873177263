import React, { useState,useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthenticationContext, getCookie } from '../components/AuthenticationContext';


function EnableTOTP() {
    const [secretCode, setSecretCode] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [step, setStep] = useState(1);
    const navigate = useNavigate();
    const location = useLocation();
    const [session, setSession] = useState(location.state?.session)
    const { auth } = useContext(AuthenticationContext);
    const username = location.state?.username;
    const [qrCodeData, setQrCodeData] = useState('');
    console.log('auth:', auth);

    const handleEnableTOTP = async (e) => {
        e.preventDefault();
        try {
            if (!session) {
                throw new Error('Session is required.');
            }

            const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/enable-totp`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ session }),
            });

            const data = await response.json();

            if (response.ok) {
                const { secretCode, session: newSession } = data;
                setSecretCode(secretCode);
                setSession(newSession);

                // Create the TOTP URI
                const issuer = encodeURIComponent('CAB432 Assignment 2'); 
                const accountName = encodeURIComponent(username || 'user@example.com'); // Use username or a default value
                const totpUri = `otpauth://totp/${issuer}:${accountName}?secret=${secretCode}&issuer=${issuer}`;

                setQrCodeData(totpUri);

                setStep(2);
            } else {
                throw new Error(data.error);
            }
        } catch (error) {
            console.error('Failed to enable TOTP:', error);
        }
    };

    

    const handleVerifyTOTP = async (e) => {
        e.preventDefault();
        try {
            if (!session) {
                throw new Error('Session is required.');
            }

            const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/enable-totp/verify-totp`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ code: verificationCode, session, username }),
            });

            const data = await response.json();

            if (response.ok) {
                navigate('/dashboard'); // Navigate to dashboard after successful TOTP setup
            } else {
                throw new Error(data.error);
            }
        } catch (error) {
            console.error('Failed to verify TOTP:', error);
        }
    };
    
    return (
        <div>
            <h1>Enable TOTP</h1>
            {step === 1 && (
                <form onSubmit={handleEnableTOTP}>
                    <button type="submit">Enable TOTP</button>
                </form>
            )}
            {step === 2 && (
                <div>
                    <p>Scan this QR code with your authenticator app:</p>
                    <img
                        src={`https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(qrCodeData)}&size=200x200`}
                        alt="TOTP QR Code"
                    />
                    <p>Or enter this code manually:</p>
                    <p><strong>{secretCode}</strong></p>
                    <form onSubmit={handleVerifyTOTP}>
                        <input
                            type="text"
                            placeholder="Verification Code"
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value)}
                        />
                        <button type="submit">Verify TOTP</button>
                    </form>
                </div>
            )}
        </div>
    );
}

export default EnableTOTP;