import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AuthenticationContext } from '../components/AuthenticationContext';
import '../css/videoStyles.css';

function VideoPage() {
    const { id } = useParams();
    const { auth, loading } = useContext(AuthenticationContext);
    const [video, setVideo] = useState(null);
    const [error, setError] = useState(null);
    const [progress, setProgress] = useState(0);
    const [videoUrl, setVideoUrl] = useState('');
    const [transcodingParams, setTranscodingParams] = useState({
        resolution: '1920x1080',
        crf: '23',
        preset: 'medium',
        audioCodec: 'aac',
        audioBitrate: '128k',
    });

    useEffect(() => {
        console.log('Auth before loading:', auth);
        if (loading) {
            // If loading is still true, don't attempt to fetch data
            console.log('Authentication is still loading...');
            return;
        }
        console.log('Auth after loading:', auth);
        if (!auth || !auth.accessToken) {
            console.log('No access token found, redirecting...');
            return;
        }

        const fetchVideo = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/videos/fetch/${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${auth.accessToken}`,
                    },
                });

                if (response.ok) {
                    const videoData = await response.json();
                    setVideo(videoData);

                    const videoResponse = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/videos/${id}/play`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${auth.accessToken}`,
                        },
                    });

                    if (videoResponse.ok) {
                        const data = await videoResponse.json();
                        setVideoUrl(data.url);
                    } else {
                        setError('Failed to fetch video URL');
                    }
                } else {
                    setError('Failed to fetch video');
                }
            } catch (err) {
                setError('Error fetching video');
                console.error(err);
            }
        };

        fetchVideo();

const eventSource = new EventSource(`${process.env.REACT_APP_API_GATEWAY_URL}/videos/${id}/progress`);
        eventSource.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.video_id === id) {
                setProgress(data.progress);
            }
            if (data.progress > 99) {
                setTimeout(() => {
                    alert('Video Transcoded!');
                    window.location.reload();
                }, 2000);
            }
        };

        return () => {
            eventSource.close();
        };
    }, [id, auth, loading]);

    if (loading) {
        return <p>Loading authentication...</p>;
    }

    if (!auth || !video) return <p>Loading...</p>;

    // Handle video transcoding
    const handleTranscode = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/videos/transcode`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${auth.accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    video_id: id, 
                    ...transcodingParams
                }),
            });

            if (response.ok) {
                const data = await response.json();
                setVideo(prev => ({ ...prev, status: 'transcoding' }));
                setProgress(0);
            } else {
                setError('Failed to start transcoding');
            }
        } catch (err) {
            setError('An error occurred while transcoding the video');
            console.error(err);
        }
    };

    // Function to handle dropdown changes for transcoding options
    const handleChange = (e) => {
        setTranscodingParams({
            ...transcodingParams,
            [e.target.name]: e.target.value,
        });
    };

    // Handle video download
    const handleDownload = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}/videos/download-url/${video.file_path}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${auth.accessToken}`,
                },
            });
            
            if (response.ok) {
                const { downloadURL } = await response.json();
                window.open(downloadURL, '_blank');
            } else {
                setError('Failed to generate download URL');
            }
        } catch (err) {
            setError('Error generating download URL');
        }
    };

    return (
        <div>
            <h1>Manage Video</h1>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <p>{video.video_name} - Status: {video.status}</p>
            {video.size && <p>Size: {formatBytes(video.size)}</p>}

            <div className="video-container">
                {videoUrl ? (
                    <video controls className="responsive-video" crossOrigin='anonymous'>
                        <source src={videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                ) : (
                    <p>Loading video...</p>
                )}
            </div>

            <button onClick={handleDownload}>Download</button>

            <h3>Transcode Video</h3>
            <label>
                Resolution:
                <select name="resolution" value={transcodingParams.resolution} onChange={handleChange}>
                    <option value="3840x2160">4K UHD (3840x2160)</option>
                    <option value="1920x1080">Full HD (1920x1080)</option>
                    <option value="1280x720">HD (1280x720)</option>
                    <option value="640x360">SD (640x360)</option>
                </select>
            </label>
            <br />
            <label>
                CRF:
                <select name="crf" value={transcodingParams.crf} onChange={handleChange}>
                    <option value="18">High Quality (18)</option>
                    <option value="23">Default (23)</option>
                    <option value="28">Lower Quality (28)</option>
                </select>
            </label>
            <br />
            <label>
                Preset:
                <select name="preset" value={transcodingParams.preset} onChange={handleChange}>
                    <option value="ultrafast">Ultra Fast</option>
                    <option value="superfast">Super Fast</option>
                    <option value="veryfast">Very Fast</option>
                    <option value="fast">Fast</option>
                    <option value="medium">Medium</option>
                    <option value="slow">Slow</option>
                    <option value="veryslow">Very Slow</option>
                </select>
            </label>
            <br />
            <button onClick={handleTranscode}>Transcode</button>

            {video.status === 'transcoding' || progress > 0 ? (
                <div>
                    <p>Transcoding Progress: {progress}%</p>
                    <progress value={progress} max="100"></progress>
                </div>
            ) : video.status === 'transcoded' && progress === 100 ? (
                <p>Transcoding complete</p>
            ) : null}
        </div>
    );
}

// Helper function to format bytes
function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export default VideoPage;
